<template>
  <div class="myplaybill">
    <van-nav-bar
      title="我的海报"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <main>
      <img class="ewm" :src="img" alt="">
    </main>
  </div>
</template>

<script>
import { getPlaybill } from '@/api'
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      img: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getimg()
  },
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
    getimg() {
      getPlaybill().then(res => {
        this.img = res.data
      })
    }
  },
};
</script>

<style scoped lang="scss">
.myplaybill {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  main {
    flex: 1;
    .ewm{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
